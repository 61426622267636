import PropTypes from 'prop-types';
import React from 'react';
import facebook from '../../images/facebook.svg';
import instagram from '../../images/instagram.svg';
import twitter from '../../images/twitter.svg';
import FooterStyles from './Footer.styled';
import Container from '../Shared/Container';

function Footer({
  instagramLink = 'https://www.instagram.com/clayton_connect/',
  facebookLink = 'https://www.facebook.com/ClaytonConnect/',
  twitterLink = 'https://twitter.com/claytonhomes',
}) {
  return (
    <FooterStyles>
      <Container>
        <div className="content">
          <div className="upper">
            <a
              className="link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.claytonhomes.com"
            >
              ClaytonHomes.com
            </a>
            <div className="social-icons">
              {instagramLink && (
                <a
                  className="icon gtm-instagram"
                  href={instagramLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={instagram} alt="Instagram Icon" title="Instagram" />
                </a>
              )}
              {facebookLink && (
                <a
                  className="icon gtm-facebook"
                  href={facebookLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={facebook} alt="Facebook Icon" title="Facebook" />
                </a>
              )}
              {twitterLink && (
                <a
                  className="icon gtm-twitter"
                  href={twitterLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={twitter} alt="Twitter Icon" title="Twitter" />
                </a>
              )}
            </div>
          </div>
          <div className="lower">
            <div className="legal">
              © 1998-{new Date().getFullYear()} Clayton. All rights
              reserved.{' '}
              <a
                className="legal-link"
                href="https://www.claytonhomes.com/legal/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Legal
              </a>{' '}
              |{' '}
              <a
                className="legal-link"
                href="https://www.claytonhomes.com/privacy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy
              </a>{' '}
              |{' '}
              <a
                className="legal-link"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open')
                }}>
                  Do Not Sell or Share My Personal Information
              </a>
            </div>
          </div>
        </div>
      </Container>
    </FooterStyles>
  );
}

Footer.propTypes = {
  instagramLink: PropTypes.string,
  facebookLink: PropTypes.string,
  twitterLink: PropTypes.string,
};

export default Footer;
