import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';
import MEDIA from '../../utils/media';

const FooterStyles = styled.footer`
  .content {
    margin: 32px auto;
    font-family: 'acumin-pro', sans-serif;
    padding: 0 24px;
    display: flex;
    flex-direction: column;

    ${MEDIA.tablet`
      padding: 0 16px;
    `}

    .upper {
      padding-bottom: 24px;
      border-bottom: 1px solid #979797;

      display: flex;
      justify-content: space-between;
      align-items: center;

      ${MEDIA.tablet`
        flex-direction: column-reverse;
      `}

      .link {
        text-decoration: none;
        font-size: 18px;
        font-weight: 600;
        color: ${Colors.primary.claytonBlue.standard};
      }

      .social-icons {
        ${MEDIA.tablet`
            margin: 0 16px 32px;
         `}
        .icon {
          margin-left: 32px;
          ${MEDIA.tablet`
            margin: 0 16px 32px;
          `}
        }
      }
    }
    .lower {
      .legal {
        margin: 16px 0;
        line-height: 24px;
        font-size: 13px;
        color: ${Colors.accent.grey1.standard};
        ${MEDIA.tablet`
          padding: 0 16px;
          text-align: center;
         `}
        .legal-link {
          text-decoration: none;
          margin: 0 8px;
          color: ${Colors.accent.grey1.standard};
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
`;

export default FooterStyles;
