import PropTypes from 'prop-types';
import React from 'react';
import LayoutStyles from './Layout.styled';

const Layout = ({ children }) => (
  <>
      <LayoutStyles />
      {children}
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
