import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { ensureReady, After } from '@jaredpalmer/after';
import { hydrate } from 'react-dom';
import routes from './routes';


ensureReady(routes).then(data => {
  return hydrate(
    <BrowserRouter>
      <After data={data} routes={routes} />
    </BrowserRouter>,
    document.getElementById('root')
  )
}
);


if (module.hot) {
  module.hot.accept();
}
