import styled from 'styled-components';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

const Container = styled.div`
  max-width: 512px;
  margin: 0 auto;

  @media only screen and (min-width: ${variables.tablet_breakpoint}px){
    
  }

  @media only screen and (min-width: ${variables.desktop_breakpoint}px){
    max-width: 1000px;
  }

`;

export default Container;
