import { createGlobalStyle } from 'styled-components';

const LayoutStyles = createGlobalStyle`
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body{
    margin: 0;
  }
  .osano-cm-widget {
    display: none;
  }
  .osano-cm-dialog {
    display: none;
  }


`;

export default LayoutStyles;
