import React, { useState } from 'react';
import posed from 'react-pose';
import menuIcon from '../../images/hamburger-icon.svg';
import connectLogo from '../../images/logo.svg';
import { getCareerSiteUrl } from '../../utils/config';
import ScrollLink from '../Shared/ScrollLink';
import HeaderStyles from './Header.styled';

const MobileNavList = posed.div({
  open: {
    height: 'auto',
    opacity: 1,
  },
  closed: {
    height: 0,
  },
});

if(typeof(window) !== "undefined" && typeof(document) !== "undefined" && !document.getElementById("ccpa_script")){
  let source = `https://privacy.claytonhomes.com/ccpa/v5/index.min.js`;
  let script = document.createElement('script');
  script.src = source;
  script.defer = true;
  script.id = "ccpa_script";
  script.setAttribute("data-class", "ccpa");
  script.setAttribute("data-id", "ccpa-banner");
  script.setAttribute("data-first-link-text", "Privacy Policy");
  script.setAttribute("data-first-link-href", "https://www.claytonhomes.com/privacy/");
  document.getElementsByTagName("head")[0].appendChild(script);
}

const Header = () => {
  const [menu, toggleMenu] = useState(false);

  return (
    <HeaderStyles>
      <div className="header-desktop">
          <div className="inner">
            <ScrollLink className="logo gtm-header-home" to="home" offset={-100} tabIndex="0">
              <img src={connectLogo} alt="Clayton Connect Logo" title="Clayton Connect Logo" />
            </ScrollLink>
            <button
              className="mobile-menu"
              onClick={() => toggleMenu(!menu)}
              type="button"
              name="menu button"
            >
              <img src={menuIcon} alt="menu-icon" />
            </button>
            <div className="nav-container">
              <ul className="nav-list">
                <li className="nav-item">
                  <ScrollLink className="gtm-header-home" to="home" tabIndex="0">Home</ScrollLink>
                </li>
                <li className="nav-item">
                  <ScrollLink
                    className="gtm-header-services" 
                    to="services"
                    tabIndex="0"
                  >
                    Services
                  </ScrollLink>
                </li>
                <li className="nav-item">
                  <a
                    className="gtm-header-careers" 
                    href={`${getCareerSiteUrl()}/our-businesses/clayton-connect`}
                    target="_blank"
                    tabIndex="0"
                  >
                    Careers
                  </a>
                </li>
                <li className="nav-item">
                  <ScrollLink
                    className="gtm-header-contact"
                    to="details"
                    tabIndex="0"
                  >
                    Contact
                  </ScrollLink>
                </li>
              </ul>
            </div>
          </div>
      </div>
      <MobileNavList className="header-mobile" pose={menu ? 'open' : 'closed'}>
        <ul className="mobile-nav-list">
          <li className="mobile-nav-item">
            <ScrollLink
              onClick={() => {
                toggleMenu(false);
              }}
              to="home"
            >
              Home
            </ScrollLink>
          </li>
          <li className="mobile-nav-item">
            <ScrollLink
              onClick={() => {
                toggleMenu(false);
              }}
              to="services"
            >
              Services
            </ScrollLink>
          </li>
          <li className="mobile-nav-item">
            <a
              href="https://careers.claytonhomes.com/our-businesses/clayton-connect"
            >
              Careers
            </a>
          </li>
          <li className="mobile-nav-item">
            <ScrollLink
              onClick={() => {
                toggleMenu(false);
              }}
              to="details"
            >
              Contact
            </ScrollLink>
          </li>
        </ul>
      </MobileNavList>
    </HeaderStyles>
  );
};

Header.propTypes = {};

export default Header;