import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Layout from './components/Layout/Layout';
import { Helmet } from 'react-helmet';

import './styles/fonts.css';

// Always set the http status code to 404.  This will set "res.statusCode" in Express.
const RouteWrapper = ({ children }) => (
  <Route
    render={({ staticContext }) => {
      if (staticContext) {
        staticContext.statusCode = 404;
      }
      return children;
    }}
  />
);

RouteWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

const NotFoundPage = () => (
  <RouteWrapper>
    <Layout>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>Clayton Connect</title>
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="clayton, transportaion, logistics" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" />
        <meta charSet="utf-8" />
      </Helmet>

      <Header siteTitle="Clayton Connect" />
      <div style={{ padding: '2rem' }}>
        <h1>404</h1>
        <p>We're sorry but you've reached a dead end.</p>
      </div>
      <Footer />
    </Layout>
  </RouteWrapper>
);

export default NotFoundPage;
