import { Link, scroller } from 'react-scroll';

import React from 'react';
import PropTypes from 'prop-types';

function ScrollLink({ to, children, useScroll = true, ...props }) {

  if (useScroll){

    let finalLink = (to.indexOf("#") === 0) ? to.substring(1) : to;

    return (
      <Link
        to={finalLink}
        spy={true}
        smooth={true}
        offset={-100}
        duration={500}
        onKeyUp={(event) => {
          if (event.key == "Enter") {
            scroller.scrollTo(finalLink, {
              smooth: true,
              offse: -100,
              duration: 500
            });
          }
        }}
        {...props}
      >
        {children}
      </Link>
    );
  }else{
    return (
      <a
        href={to}
        {...props}
      >
        {children}
      </a>
    );
  }

}

ScrollLink.propTypes = {
  to: PropTypes.string.isRequired,
};

export default ScrollLink;
