import * as fs from 'fs';
import * as https from 'https';

export const BASE_AWS_API_URL = process.env.NODE_ENV === 'production' ? '#{BASE_AWS_API_URL}' : 'https://api.claytonhomes.dev.cct-pubweb.com/';
export const BASE_API_URL = process.env.NODE_ENV === 'production' ? '#{BASE_API_URL}' : 'https://dev.cmhinfo.biz/WebServices2/api/';
export const ENVIRONMENT = process.env.NODE_ENV === 'production' ? '#{terraformEnvCode}' : "dev";

export const LAMBDA_TOKEN = '#{lambda_token}';

export const FS_DEV_EXISTS = (typeof fs.readFileSync !== 'undefined');

export const ALERT_DISMISS_EXPIRE_MS = 86400000; // Number of milliseconds to wait after alert dismissed before displaying again. (e.g. 3600000 = 60 min, 86400000 = 24 hrs)
export const CH_ALERTS_CLOSED_KEY = 'chAlertsClosed' // Local storage key for tracking CH.com banner alerts that have been dismissed
export const CH_CONNECT_WEBSITE_BANNER_CONTENTFUL_KEY = '6TjSx597GiOglWWTqKmM06'; // Contentful key for CH.com Website Banner data

export const getCertAgent = () => {
    return ((ENVIRONMENT === "dev" || ENVIRONMENT === "itg" || ENVIRONMENT === "qua") && FS_DEV_EXISTS) ? 
    new https.Agent({ ca: fs.readFileSync(`./ClaytonRootCA.cer`), keepAlive: false })
    : null;
}

export const getBaseAwsApiUrl = () => {
    return BASE_AWS_API_URL;
};

export const getBaseApiUrl = () => {
    return BASE_API_URL;
};

export const getSiteUrl = (relativeUrl = "") => {

    //This is to overstep a local issue where local develop uses the full path automatically.
    if (relativeUrl.indexOf("localhost") !== -1){
        return relativeUrl;
    }

    let domain = (ENVIRONMENT.indexOf('terraformEnvCode') !== -1) ? "localhost:1234" : `clayton-connect.${ENVIRONMENT}.cct-pubweb.com`;

    if(`${ENVIRONMENT}` == "dev"){
        domain = "clayton-connect.dev.cct-pubweb.com";
    }

    if (ENVIRONMENT.indexOf('staging') !== -1 || ENVIRONMENT.indexOf('prod') !== -1){
        domain = "www.clayton-connect.com";
    } 

    return `https://${domain}${relativeUrl}`;
};

export const getCareerSiteUrl = () => {
    let url = (ENVIRONMENT === "#{terraformEnvCode}" || ENVIRONMENT === "prod" || ENVIRONMENT === "preview" || ENVIRONMENT === "staging") ? "https://careers.claytonhomes.com" : `https://career-sites.${ENVIRONMENT}.cct-pubweb.com`;
    
    return url;
};

const scrollToContainer = (idName) => {
    //So, yes, this is overkill when an anchor tag would work, but I am having an issue with anchor links not navigating
    //correctly. Something to investigate later! - John.net
    //got this working properly -- the lazy load around the find a retailer section was breaking it bc the function couldn't
    //find the id of the container -- Jamie.html
    const yOffset = (isDesktop) ? -85 : -20;
    
    let ele = document.getElementById(idName);
    if (ele){
      const y = ele.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({top: y, behavior: 'smooth' });
    }
  };


