import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';

import MEDIA from '../../utils/media';

const HeaderStyles = styled.header`
  position: sticky;
  width: 100%;
  top: -1px;
  z-index: 99999;
  .header-desktop {
    background-color: white;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);

    .inner {
      display: flex;
      justify-content: space-between;
      height: 90px;
      max-width: 100%;
    }

    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 32px 0 24px;
      cursor: pointer;
    }
    .mobile-menu {
      display: none;

      ${MEDIA.tablet`
      width: 96px;
      display: block;
      border: none;
      border-left: 1px solid #eee;
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      outline: none;
    `}
    }
    .nav-container {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      max-width: 900px;

      ${MEDIA.tablet`
          display: none;
      `}
    }
    .nav-list {
      display: flex;
      justify-content: flex-end;
      list-style: none;
      margin: 0 24px 0 0;
      padding: 0;
      width: 100%;

      ${MEDIA.tablet`
      display: none;
   `}

      .nav-item {
        display: flex;
        align-items: center;
        font-size: 18px;
        margin-left: 32px;



        a {
          background-color: #ffffff;
          border: 2px solid transparent;
          color: #000;
          cursor: pointer;
          display: block;
          padding: 24px 0;
          text-decoration: none;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          &.active {
            background-color: #ffffff;
            color: ${Colors.primary.black.standard};
          }
        }
        &:hover {
          a {
            color: ${Colors.primary.claytonBlue.standard};
          }
        }
      }
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      /* IE11 fixes */
      display: block;

      .logo {
        display: block;
        float: left;
        padding-top: 20px;
      }
      .nav-container {
        display: block;
        flex-grow: unset;
        float: right;
        max-width: auto;

        .nav-list .nav-item {
          padding: 0 30px;
        }
      }
    }
  }

  .header-mobile {
    display: none;
    height: 0;
    z-index: 100;
    width: 100%;
    background: white;
    top: 89px;
    position: absolute;
    background-color: white;

    ${MEDIA.tablet`
      display: block;
      height: 0;
      overflow: hidden;
      border-top: 1px solid #eee;
   `}
    .mobile-nav-list {
      margin: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      padding: 8px 16px 0 16px;

      .mobile-nav-item {
        flex: 1;
        margin: 4px 0;
        width: 100%;

        a {
          border-radius: 4px;
          color: ${Colors.primary.black.standard};
          cursor: pointer;
          display: block;
          font-size: 18px;
          font-weight: bold;
          padding: 16px;
          text-align: center;
          text-decoration: none;
        }
        &:hover {
          a {
            color: ${Colors.primary.claytonBlue.standard};
            font-weight: bold;
          }
        }
        &:visited {
          a {
            background-color: #ffffff;
            color: ${Colors.primary.claytonBlue.standard};
            font-weight: bold;
          }
        }
      }
    }
    .nav-container {
      margin: 0 16px;
      list-style: none;
      display: flex;
      flex-direction: column;
    }
  }
`;

export default HeaderStyles;
