import { asyncComponent } from '@jaredpalmer/after';
import NotFoundPage from './404';

export default [
  {
    path: '/',
    exact: true,
    component: asyncComponent({
      loader: () => import('./App')
    })
  },
 {
      //404
      component: NotFoundPage
  }
];